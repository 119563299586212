<script>
import PrivacyRevForm from "@/views/menu/privacy/revisions/privacyRevForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    privacyService: "privacyService",
  },
})
export default class PrivacyRevEdit extends mixins(PrivacyRevForm) {
  formId = "edit-privacy-rev-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save
      },
    ];
  }

  get idPrivacy() {
    return this.$route.params.idPrivacy;
  }

  async save(passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      if (this.model.keepRev)
        await this.privacyService.updateRev(this.idPrivacy, {
          id: this.model.id,
          type: this.model.type,
          required: this.model.isRequired,
          localizations: this.model.localizations,
        });
      else
        await this.privacyService.addRev(this.idPrivacy, {
          type: this.model.type,
          required: this.model.isRequired,
          localizations: this.model.localizations,
        });

      this.$router.push(`/privacy/revisions/${this.idPrivacy}/list`);

      toastService.success(
        this.model.keepRev
          ? this.translations.success.privacyUpdate_update
          : this.translations.success.privacyUpdate_create
      );
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.privacyRev_edit;
  }

  async afterCreate() {
    this.model = await this.privacyService.getRev(
      this.$route.params.idPrivacyRev
    );
    this.model.keepRev = false;
    this.isReady = true;
  }
}
</script>
